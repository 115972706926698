import React, { memo } from 'react'
import './Empty.scss'
import { useTrans } from '@/hooks/useTranslation'
import ImageListEmpty from '@/images/list-empty.png'
import clsx from 'clsx'

interface PropsType {
  image?: any
  description?: string
  imageStyle?: any
  className?: string
}
function Empty({ image = ImageListEmpty, description, imageStyle, className }: PropsType) {
  const { t } = useTrans()
  return (
    <div className={clsx('cmp-empty', className)}>
      <img className="cmp-empty__img" src={image} style={imageStyle} />
      <span className="cmp-empty__description">{description ? t(description) : t('Common.NoData')}</span>
    </div>
  )
}

export default memo(Empty)
