import React, { useCallback, useEffect, useRef, useState } from 'react'
import Header from '@/components/Header'
import './AgencySearch.scss'
import { useTrans } from '@/hooks/useTranslation'
import { useNavigate } from 'react-router-dom';
import { SearchBar } from 'antd-mobile'
import { guildSearch, userSearch } from '@/utils/request';
import { toast } from '@/utils/bridge';
import Empty from '@/components/Empty';

function AgencySearch() {
    const { t } = useTrans()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    const [searchKey, setSearchKey] = useState<string>()
    const refSearchKey = useRef(searchKey)
    refSearchKey.current = searchKey

    const [user, setUser] = useState<Guild.GuildSearchUserItem>()
    const [empty, setEmpty] = useState(false)
    const [guildList, setGuildList] = useState<{
        list: Guild.GuildSearchListItem[]
        page: number
        hasMore: boolean
        empty: boolean
    }>({
        list: [],
        page: 1,
        hasMore: true,
        empty: false
    })
    const refGuildList = useRef(guildList)
    refGuildList.current = guildList

    // const handleSelect = useCallback((item: Guild.GuildSearchUserItem) => {
    //     navigate(`/guild/create/submit?inviteUid=${item.uid}`, { replace: true })
    // }, [])
    const handleSelect = useCallback((item: Guild.GuildSearchUserItem) => {
        navigate(`/guild/create/submit?erbanNo=${item.erbanNo}`, { replace: true })
    }, [])

    const handleSearchChange = useCallback((val: string) => {
        setSearchKey(val)
    }, [])

    const fetchGuildList = useCallback(async () => {
        if (!refGuildList.current.hasMore) return

        setLoading(true)
        const { code, data, message } = await guildSearch({ searchKey: refSearchKey.current, pageNum: refGuildList.current.page })
        setLoading(false)
        if (code !== 200) {
            toast(message)
            return
        }
        if (!data) return
        setGuildList({
            ...refGuildList.current,
            list: [
                ...(refGuildList.current.page === 1 ? [] : refGuildList.current.list),
                ...(data.list || [])
            ],
            page: data.whetherLastPage ? refGuildList.current.page : refGuildList.current.page + 1,
            hasMore: !data.whetherLastPage
        })
    }, [])


    const handleSearch = useCallback(async () => {
        if (!refSearchKey.current) return

        setLoading(true)
        const { code, data, message } = await userSearch(refSearchKey.current)
        setLoading(false)
        if (code !== 200) {
            toast(message)
            return
        }

        setUser(data)
        setEmpty(!data || !data.uid)
    }, [])

    return (
        <div className='pg-guild-agency-search'>
            <Header
                title={t('Common.Agency').toString()}
            />
            <main>
                <div className='pg-guild-agency-search__bar'>
                    <SearchBar placeholder={t('Guild.CreateAgency.EnterUserIDToSearch').toString()} onChange={handleSearchChange} />
                    <div className='pg-guild-agency-search__bar-confirm' onClick={handleSearch}>{t('Common.Search')}</div>
                </div>
                {/* <ScrollList
                    loading={loading}
                    hasMore={guildList.hasMore}
                    loadMore={fetchGuildList}
                    empty={!guildList.list.length}
                    className='pg-guild-agency-search__scroll'
                >
                    {guildList.list.map((item) => {
                        return <div key={item.guildNo} onClick={() => handleSelect(item)} className='pg-guild-agency-search__item'>
                            <img className='pg-guild-agency-search__item-avatar' src={item.coverPicUrl}></img>
                            <div className="pg-guild-agency-search__item-info">
                                <span>{item.guildName}</span>
                                <div className="pg-guild-agency-search__item-middle">
                                    <span>ID:{item.guildNo}</span>
                                    <div className='pg-guild-agency-search__item-people-icon'>
                                        <img src={ImgPeople} alt="" />
                                        <span>{item.memberCount}</span>
                                    </div>
                                </div>
                                <span>{item.guildSlogan}</span>
                            </div>
                            <img className='pg-guild-agency-search__item-logo' src={item.guildLevelIcon} />
                        </div>
                    })}
                    
                </ScrollList > */}
                {user && user.uid && <div onClick={() => handleSelect(user)} className='pg-guild-agency-search__item'>
                    <img className='pg-guild-agency-search__item-avatar' src={user.avatar}></img>
                    <div className="pg-guild-agency-search__item-info">
                        <span>{user.nick}</span>
                        <div className="pg-guild-agency-search__item-middle">
                            <span>ID:{user.erbanNo}</span>
                            {/* <div className='pg-guild-agency-search__item-people-icon'>
                                <img src={ImgPeople} alt="" />
                                <span>{item.memberCount}</span>
                            </div> */}
                        </div>
                        {/* <span>{item.guildSlogan}</span> */}
                    </div>
                    {/* <img className='pg-guild-agency-search__item-logo' src={item.guildLevelIcon} /> */}
                </div>}
                {empty && <Empty />}
            </main>
        </div>
    )
}

export default AgencySearch